<script setup>
import { useAnalyticsApi } from "~/composables/useAnalyticsApi";
import { useAnalyticsStore } from "~/store/useAnalyticsStore";
import { storeToRefs } from "pinia";

const { linkId } = defineProps({
  linkId: {
    type: Number,
    required: false,
  },
});

const route = useRoute();
const collectionId = route.params.id;

const { data, loading, fetchLocationsData } = useAnalyticsApi();
const { selectedDateRange, filters, filtersValues } = storeToRefs(useAnalyticsStore());

const fetchData = () => fetchLocationsData({ collectionId, linkId });

onMounted(fetchData);

watch([selectedDateRange, filters, filtersValues], fetchData);
</script>

<template>
  <div class="h-full">
    <div v-if="loading" class="loading-overlay rounded-xl">
      <LoadersRingLoader />
    </div>

    <div class="grid-container grid-auto-rows remove-scroll">
      <div class="col-span-2">
        <!-- Region Section -->
        <div class="chart-section-class">
          <div class="chart-left-class">
            <ChartRegionMap :data="data?.regions" />
          </div>
          <div class="chart-right-class">
            <ExpandableTableLocations
              :data="data?.regions"
              :totalClicks="data?.total_clicks"
              :label="'Regional'"
              :colKey="'regions'"
            />
          </div>
        </div>
        <div class="chart-section-class">
          <div class="chart-left-class">
            <ChartStatesSummary :data="data?.states" :totalClicks="data?.total_clicks" />
          </div>
          <div class="chart-right-class">
            <ExpandableTableLocations
              :data="data?.states"
              :totalClicks="data?.total_clicks"
              :label="'States'"
              :colKey="'states'"
            />
          </div>
        </div>
        <div class="chart-section-class">
          <div class="chart-left-class">
            <ChartCitiesSummary :data="data?.cities" :totalClicks="data?.total_clicks" />
          </div>
          <div class="chart-right-class">
            <ExpandableTableLocations
              :data="data?.cities"
              :totalClicks="data?.total_clicks"
              :label="'Cities'"
              :colKey="'cities'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.grid-container {
  @apply grid grid-cols-2 h-full gap-4 overflow-y-auto;
}

.chart-section-class {
  @apply grid grid-cols-7 !gap-4 border rounded-2xl h-[36rem];
}

.chart-section-class:nth-of-type(2) {
  @apply my-4;
}

.chart-left-class {
  @apply col-span-7 md:col-span-7  lg:col-span-4 p-2 h-full;
}

.chart-right-class {
  @apply col-span-7 md:col-span-7 lg:col-span-3 h-full overflow-hidden;
}
</style>
